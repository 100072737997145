exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-attractions-bk-jsx": () => import("./../../../src/pages/Attractions_bk.jsx" /* webpackChunkName: "component---src-pages-attractions-bk-jsx" */),
  "component---src-pages-attractions-jsx": () => import("./../../../src/pages/Attractions.jsx" /* webpackChunkName: "component---src-pages-attractions-jsx" */),
  "component---src-pages-buy-now-jsx": () => import("./../../../src/pages/BuyNow.jsx" /* webpackChunkName: "component---src-pages-buy-now-jsx" */),
  "component---src-pages-checkout-jsx": () => import("./../../../src/pages/Checkout.jsx" /* webpackChunkName: "component---src-pages-checkout-jsx" */),
  "component---src-pages-cities-jsx": () => import("./../../../src/pages/Cities.jsx" /* webpackChunkName: "component---src-pages-cities-jsx" */),
  "component---src-pages-create-pass-jsx": () => import("./../../../src/pages/CreatePass.jsx" /* webpackChunkName: "component---src-pages-create-pass-jsx" */),
  "component---src-pages-details-jsx": () => import("./../../../src/pages/Details.jsx" /* webpackChunkName: "component---src-pages-details-jsx" */),
  "component---src-pages-egypt-travel-jsx": () => import("./../../../src/pages/EgyptTravel.jsx" /* webpackChunkName: "component---src-pages-egypt-travel-jsx" */),
  "component---src-pages-fa-qs-jsx": () => import("./../../../src/pages/FAQs.jsx" /* webpackChunkName: "component---src-pages-fa-qs-jsx" */),
  "component---src-pages-how-it-works-jsx": () => import("./../../../src/pages/HowItWorks.jsx" /* webpackChunkName: "component---src-pages-how-it-works-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-package-details-jsx": () => import("./../../../src/pages/PackageDetails.jsx" /* webpackChunkName: "component---src-pages-package-details-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/PrivacyPolicy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-profile-jsx": () => import("./../../../src/pages/Profile.jsx" /* webpackChunkName: "component---src-pages-profile-jsx" */),
  "component---src-pages-reservations-jsx": () => import("./../../../src/pages/Reservations.jsx" /* webpackChunkName: "component---src-pages-reservations-jsx" */),
  "component---src-pages-savings-guarantee-jsx": () => import("./../../../src/pages/SavingsGuarantee.jsx" /* webpackChunkName: "component---src-pages-savings-guarantee-jsx" */),
  "component---src-pages-terms-of-service-jsx": () => import("./../../../src/pages/TermsOfService.jsx" /* webpackChunkName: "component---src-pages-terms-of-service-jsx" */),
  "component---src-pages-things-to-do-jsx": () => import("./../../../src/pages/ThingsToDo.jsx" /* webpackChunkName: "component---src-pages-things-to-do-jsx" */)
}

